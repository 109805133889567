@import "./core/color";
@import "./core/method";

.dialogWrap {
  height: 625px;
  background: url(../images/loginBg.jpg) no-repeat center;
}

.wrapper {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

// 登录注册
.dialog_box {
  // float: right;
  display: none;
  position: absolute;
  top: 78px;
  right: 0;
  width: 508px;
  height: 0;
  background-color: $white;
  // margin-top: 78px;
  padding: 27px;
  box-sizing: border-box;

  &.show {
    display: block;
    height: 487px;
    // height: 100%;
  }
}

.dialog {
  &_title {
    line-height: 70px;
    font-size: 32px;
    text-align: center;
  }

  &_input_box {
    position: relative;
    width: 455px;
    height: 68px;
    line-height: 68px;
    font-size: 18px;
    text-align: center;
    color: $defaultGray;
    background-color: #fff;
    border: solid 1px $userBorder;
    margin-bottom: 30px;

    &.dialog-error {
      // border: solid 1px $;
      border: solid 1px red;
      .dialog_error_tips {
        font-size: 14px;
      }
    }
    &.dialog-error .dialog_error_tips {
      font-size: 14px;
    }

    &.focus {
      border: solid 1px $mainBlue;
    }

    // &.erro{
    //   border: solid 1px $mainRed;
    // }
    .iconBg {
      float: left;
      width: 79px;
      height: 68px;
      background-color: #eeeeee;
    }

    .icon {
      display: inline-block;
      margin: 15px auto 0;
    }

    .dialog_input_tips {
      float: left;
      margin-left: 15px;

      &.getcode {
        cursor: pointer;

        &:hover {
          color: $mainBlue;
        }
      }
    }

    .dialog_input {
      float: left;
      color: $defaultGray;
      width: 315px;
      background: $white;
      width: 230px;
      display: inline-block;
      margin-top: 23px;
      padding-left: 20px;
    }

    .dialog_error_tips {
      font-size: 0;
      color: red;
      text-align: left;
      position: absolute;
      bottom: -17px;
      line-height: 1px;
      display: block;
      width: 100%;
      left: 0;
    }
  }
}

.dialog_footer_left {
  float: left;
}

.dialog_footer_right {
  float: right;
}

.dialog_input_btn {
  cursor: pointer;
  border-radius: 5px;
  @include smallBtn(454px, 64px, $mainBlue);
}

.dialog_other_item {
  // float: left;
  font-size: 18px;
  margin-top: 23px;
  display: block;
  cursor: pointer;
  color: #050505;

  &:hover {
    color: $defaultGray;
  }
}

.dialog_footer_tips {
  font-size: 18px;
  color: #7a7a7a;
  text-align: center;
  cursor: default;
}

.dialog_input::-webkit-input-placeholder {
  color: $defaultGray;
}

.dialog_input::-moz-placeholder {
  color: $defaultGray;
}

.dialog_input:-ms-input-placeholder {
  color: $defaultGray;
}

.dialog_input_tips.getcode.disabled {
  &:hover {
    color: #a8a8a8;
  }

  color: #a8a8a8;
  cursor: default;
}

.dialog_close {
  display: inline-block;
  width: 12px;
  height: 11px;
  background: url(../images/img/dialog_close.png) no-repeat;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 101;
  cursor: pointer;
}

.dialog_back {
  width: 10px;
  height: 10px;
  border-top: 2px solid #aaa;
  border-left: 2px solid #aaa;
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  margin-top: -7px;
  cursor: pointer;

  &:hover {
    border-color: $mainBlue;
  }
}
//重置成功
.dialog_smile {
  width: 64px;
  height: 64px;
  display: block;
  background: url(../images/img/smile.png) no-repeat;
  // margin-top: 30px;
  margin: 30px auto;
}

.dialog_pss_notice {
  font-size: 16px;
  margin: 0px 0 50px;
  text-align: center;
}
.dialog_pss_tips {
  color: #999;
  line-height: 26px;
  font-size: 14px;
  margin-top: 50px;
}

